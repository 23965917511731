.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--primary-green);
  overflow-x: hidden;
  padding: 20px;
}

.sidebar h3{
  color: white;
  font-size: 24px;
  margin-left: -20px;
}

.logo {
  text-align: center;
  margin-bottom: 30px;
}

.logo img {
  width: 120px;
}

.sidebar-item {
  display: flex;
  font-size: 15px;
  align-items: center;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  margin-bottom: 10px;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: whitesmoke;
  border-radius: 5px;
}

.sidebar-item.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
}

.sidebar-icon {
  margin-right: 10px;
}

.sidebar-account {
  display: flex;
  color: white;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #ccc;
  font-size: 15px;
}

.sidebar-account svg {
  margin-right: 10px;
}

