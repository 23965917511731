:root {
  --dark-green: #005E3F;
  --light-green: #007A52;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.help-support {
  flex: 1;
}

.help-hero {
  background: var(--dark-green);
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.help-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.help-hero h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.help-hero p {
  font-size: 1.2rem;
  color: #e2e8f0;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.support-channels,
.documentation-section,
.faq-section {
  margin-bottom: 4rem;
}

.content-container h2 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 2rem;
  text-align: center;
}

.channels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.channel-card {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
}

.channel-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.1);
}

.channel-icon {
  width: 48px;
  height: 48px;
  margin: 0 auto 1.5rem;
  color: #4a5568;
}

.support-icon {
  width: 100%;
  height: 100%;
}

.channel-card h3 {
  color: #2d3748;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.channel-card .description {
  color: #718096;
  margin-bottom: 1rem;
}

.channel-card .contact {
  color: #4a5568;
  font-weight: 600;
  margin: 1rem 0;
}

.channel-card .hours {
  color: #a0aec0;
  font-size: 0.9rem;
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.resource-card {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 2rem;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resource-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.1);
  border-color: #4a5568;
}

.resource-icon {
  width: 40px;
  height: 40px;
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.resource-card h3 {
  color: #2d3748;
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
}

.resource-card p {
  color: #718096;
  font-size: 0.95rem;
}

.faq-grid {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
}

.faq-card {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 1.75rem;
  transition: all 0.3s ease;
}

.faq-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.1);
}

.faq-card h3 {
  color: #2d3748;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.faq-card p {
  color: #718096;
  font-size: 0.95rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .help-hero {
    padding: 3rem 1.5rem;
  }

  .help-hero h1 {
    font-size: 2.25rem;
  }

  .help-hero p {
    font-size: 1.1rem;
  }

  .content-container {
    padding: 2rem 1.5rem;
  }

  .content-container h2 {
    font-size: 1.75rem;
  }

  .channels-grid,
  .resources-grid {
    grid-template-columns: 1fr;
  }

  .channel-card,
  .resource-card,
  .faq-card {
    padding: 1.5rem;
  }
}
