.customer-acquisition-container{
  display: flex;
  align-items: center;
  height: 100vh; 
}

.customer-acquisition {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

}

.customer-acquisition h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.customer-acquisition p {
  font-size: 16px;
  margin-bottom: 20px;
}

.input-field {
  margin-bottom: 20px;
}

.input-field label {
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 5px;
}

.input-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.calculate-button {
  margin-left: 10px;
  color: #4caf50;
  text-decoration: underline;
  cursor: pointer;
}

.continue-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}