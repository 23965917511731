.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 2rem;
    background-color: rgba(255, 255, 255, 0.98);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: none;
    z-index: 1000;
    height: 80px;
}

.header-scrolled {
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
    padding: 0.8rem 2rem;
    height: 70px;
}

.logo {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 30px;
}

.logo a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logo img {
    width: 180px !important;
    height: auto;
    transition: all 0.3s ease;
    display: block;
}

.header-scrolled .logo img {
    width: 160px !important;
}

nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 2rem;
}

nav li {
    margin: 0;
}

nav a {
    color: #1a1a1a;
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    transition: color 0.2s ease;
    position: relative;
}

nav a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: var(--primary-green);
    transition: width 0.2s ease;
}

nav a:hover {
    color: var(--primary-green);
}

nav a:hover::after {
    width: 100%;
}

.signup {
    padding: 0.8rem 1.6rem;
    background-color: var(--primary-green);
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.95rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.signup:hover {
    background-color: #033e2a;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    color: var(--primary-green);
    cursor: pointer;
}

.mobile-only {
    display: none;
}

@media (max-width: 768px) {
    .header {
        flex-wrap: wrap;
        padding: 1rem;
    }

    .logo {
        margin-top: 20px;
        margin-bottom: 0px !important;
    }

    .logo img {
        width: 150px !important;
    }

    .nav {
        display: none;
        width: 100%;
        order: 3;
    }

    .nav-open {
        display: block;
    }

    nav ul {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    nav li {
        margin: 1rem 0;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .menu-toggle {
        display: block;
        order: 3;
    }

    .nav .signup {
        margin-top: 1rem;
        padding: 0.8rem 1.5rem;
        font-size: 14px;
    }
}