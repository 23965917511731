.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6rem 2rem 4rem;
    background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 1),
        rgba(240, 249, 245, 0.8)
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.hero-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23005c3b' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.3;
    pointer-events: none;
    animation: patternFade 1s ease-in;
}

.hero::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle at 0% 0%,
        rgba(var(--primary-green-rgb), 0.08) 0%,
        transparent 60%
    );
    top: 0;
    left: 0;
}

.hero-content {
    flex: 1;
    max-width: 650px;
    position: relative;
    z-index: 1;
    animation: slideUp 0.8s ease-out;
}

.hero-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(255, 255, 255, 0.9);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    margin-bottom: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    font-weight: 500;
    color: #2c3e50;
    backdrop-filter: blur(10px);
    animation: slideDown 0.6s ease-out;
}

.hero-badge span {
    animation: bounce 2s infinite;
}

.hero-text {
    margin-bottom: 3rem;
}

.hero-text h1 {
    font-size: 4.5rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    line-height: 1.1;
    color: #1a1a1a;
    letter-spacing: -0.02em;
}

.gradient-text {
    background: linear-gradient(120deg, var(--primary-green), #2c8259);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.hero-text p {
    font-size: 1.25rem;
    color: #4a4a4a;
    line-height: 1.7;
    font-weight: 400;
    max-width: 540px;
}

.hero-actions {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 500px;
}

.hero-form {
    background: white;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease;
}

.hero-form:hover {
    transform: translateY(-2px);
}

.form-label {
    margin-bottom: 1rem;
    font-weight: 500;
    color: #333;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.input-group {
    display: flex;
    gap: 0.75rem;
    max-width: 100%;
}

.input-group input {
    flex: 1;
    padding: 0.875rem 1.25rem;
    border: 2px solid #e1e1e1;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: #f8f8f8;
    color: #333;
    min-width: 0;
}

.input-group input:focus {
    outline: none;
    border-color: var(--primary-green);
    box-shadow: 0 0 0 4px rgba(var(--primary-green-rgb), 0.1);
    background: white;
}

.primary-btn {
    padding: 0.875rem 1.5rem;
    background-color: var(--primary-green);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.primary-btn:hover {
    background-color: #033e2a;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.secondary-cta {
    display: flex;
    justify-content: center;
}

.secondary-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem 1.5rem;
    background: transparent;
    color: #2c3e50;
    border: 2px solid #e1e1e1;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.secondary-btn:hover {
    border-color: var(--primary-green);
    background: rgba(var(--primary-green-rgb), 0.05);
    transform: translateY(-2px);
}

.social-proof {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    opacity: 0;
    animation: fadeIn 0.8s ease-out 0.6s forwards;
}

.ratings {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #2c3e50;
}

.stars {
    color: #ffd700;
    letter-spacing: 2px;
}

.brands {
    display: flex;
    align-items: center;
    gap: 2rem;
    opacity: 0.6;
}

.brands img {
    height: 20px;
    width: auto;
    filter: grayscale(1);
    transition: all 0.3s ease;
}

.brands img:hover {
    filter: grayscale(0);
    opacity: 1;
}

.hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    position: relative;
    z-index: 1;
    animation: slideIn 0.8s ease-out;
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);
    }
}

@keyframes patternFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.3;
    }
}

@media (max-width: 1024px) {
    .hero {
        flex-direction: column;
        text-align: center;
        padding: 6rem 2rem 3rem;
    }

    .hero-content {
        max-width: 600px;
    }

    .hero-text p {
        max-width: 100%;
    }

    .hero-text h1 {
        font-size: 3.5rem;
    }

    .hero-form {
        text-align: left;
    }

    .input-group {
        flex-direction: column;
    }

    .input-group input,
    .primary-btn {
        width: 100%;
    }

    .social-proof {
        align-items: center;
    }

    .brands {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .hero {
        padding: 4rem 1.5rem 2rem;
    }

    .hero-text h1 {
        font-size: 2.75rem;
    }

    .hero-form {
        padding: 1.5rem;
    }

    .hero-badge {
        font-size: 0.875rem;
    }
}

.form-message {
    margin-top: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 500;
    animation: fadeIn 0.3s ease-out;
}

.form-message.success {
    background-color: rgba(0, 128, 0, 0.1);
    color: #006400;
    border-left: 3px solid #006400;
}

.form-message.error {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545;
    border-left: 3px solid #dc3545;
}