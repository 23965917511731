.term-page {
  padding: 2rem;
  
  margin: 0 auto;
  background: #f8f9fa;
  min-height: calc(100vh - 80px);
}

.term-header {
  background: linear-gradient(135deg, #005E3F 0%, #007A52 100%);
  padding: 6rem 2rem;
  margin: -2rem -2rem 2rem -2rem;
  color: white;
  position: relative;
  overflow: hidden;
}

.term-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.2'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.15;
  z-index: 0;
}

.term-header-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.term-header h1 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.term-content {
  background: white;
  border-radius: 16px;
  padding: 2.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-top: -4rem;
  position: relative;
  z-index: 2;
}

.term-definition {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #f0f0f0;
}

.term-definition h2 {
  color: #005E3F;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.term-definition p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #4a5568;
}

.term-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  margin-top: 2rem;
}

.usage-examples, .related-resources {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid #e9ecef;
}

.usage-examples h2, .related-resources h2 {
  color: #005E3F;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.related-resources ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.related-resources li {
  padding: 1rem;
  margin-bottom: 0.5rem;
  background: white;
  border-radius: 8px;
  color: #007A52;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.related-resources li:hover {
  background: #e9ecef;
  transform: translateX(4px);
}

.related-resources li::before {
  content: "→";
  margin-right: 0.75rem;
  color: #007A52;
}

.term-not-found {
  text-align: center;
  padding: 4rem 2rem;
}

.term-not-found h1 {
  color: #dc3545;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.term-not-found p {
  color: #6c757d;
  font-size: 1.2rem;
}

.back-to-dictionary {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background: #007A52;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  margin-top: 2rem;
  transition: background 0.3s ease;
}

.back-to-dictionary:hover {
  background: #005E3F;
}

@media (max-width: 768px) {
  .term-details {
    grid-template-columns: 1fr;
  }
  
  .term-header h1 {
    font-size: 2.5rem;
  }
  
  .term-content {
    padding: 1.5rem;
  }
}
