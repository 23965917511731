.modelHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modelHeadContent{
    display: flex;
    padding: 10px;
    background-color: antiquewhite;
    border-radius: 6px;
    gap: 40px;
}

.modelHead .changeBtn{
    background-color: var(--primary-green);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}