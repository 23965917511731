.revenue-streams {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.revenue-streams h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.revenue-streams p {
  font-size: 16px;
  margin-bottom: 20px;
}

.input-fields {
  margin-bottom: 20px;
}

.input-fields h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.input-fields input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-fields button {
  padding: 10px 20px;
  background-color: var(--primary-orange);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input-fields .input-group{
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}

.revenue-streams-list {
  margin-bottom: 20px;
}

.revenue-streams-list h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.revenue-streams-list table {
  width: 100%;
  border-collapse: collapse;
}

.revenue-streams-list th,
.revenue-streams-list td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.total-revenue {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.continue-button-container{
  display: flex;
  justify-content: flex-end;
}

.continuebutton {
  padding: 10px 20px !important;
  background-color: var(--primary-green) !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer;
  width: auto;
  text-transform: inherit !important;
  font: inherit !important;
}

