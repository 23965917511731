.contact-page {
    min-height: 100vh;
    background: linear-gradient(to bottom, #fff, #f8f9fa);
}

.contact-content {
    padding-top: 80px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.contact-hero {
    padding: 6rem 0 4rem;
    text-align: center;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 1), rgba(240, 249, 245, 0.8));
}

.contact-hero h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    background: linear-gradient(120deg, var(--primary-green), #2c8259);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-hero .lead {
    font-size: 1.5rem;
    color: #4a4a4a;
    max-width: 600px;
    margin: 0 auto;
}

.contact-options {
    padding: 5rem 0;
}

.contact-grid {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 4rem;
    margin-top: 2rem;
}

.contact-form-container {
    background: white;
    padding: 3rem;
    border-radius: 16px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
}

.contact-form-container h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 2rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #4a4a4a;
    font-weight: 500;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.875rem 1.25rem;
    border: 2px solid #e1e1e1;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: #f8f8f8;
    color: #333;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--primary-green);
    box-shadow: 0 0 0 4px rgba(var(--primary-green-rgb), 0.1);
    background: white;
}

.submit-btn {
    width: 100%;
    padding: 1rem 2rem;
    background-color: var(--primary-green);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-btn:hover {
    background-color: #033e2a;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.info-card {
    background: white;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.info-card:hover {
    transform: translateY(-5px);
}

.info-card h3 {
    color: var(--primary-green);
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.info-card p {
    color: #4a4a4a;
    margin-bottom: 1rem;
    line-height: 1.6;
}

.info-card a {
    color: var(--primary-green);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.info-card a:hover {
    color: #033e2a;
}

@media (max-width: 1024px) {
    .contact-grid {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .contact-form-container {
        padding: 2rem;
    }
}

@media (max-width: 768px) {
    .contact-hero h1 {
        font-size: 2.5rem;
    }

    .contact-hero .lead {
        font-size: 1.25rem;
    }

    .contact-options {
        padding: 3rem 0;
    }
}

@media (max-width: 480px) {
    .contact-form-container {
        padding: 1.5rem;
    }

    .info-card {
        padding: 1.5rem;
    }
}
