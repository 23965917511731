.loginSection{
  display: flex;
  height: 100vh;
}

.signup-image{
  width: 50%;
  background-color: whitesmoke;
  padding : 2.5rem;
}

.sign-up {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
}

.input-group{
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 6px;
  background-color: white;
}

.sign-up input,
.sign-up button {
  margin-bottom: 10px;
  padding: 10px;
  width: 300px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  color: var(--dark-green);
}

.sign-up .google-btn{
  background-color: white;
  color: var(--primary-green);
  border: 1px solid var(--primary-green);
}

.sign-up button {
  background-color: var(--primary-green);
  color: whitesmoke;
  border: none;
  cursor: pointer;
}

.sign-up p {
  margin-top: 20px;
}

.sign-up h1{
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--primary-green);
}
