.footer {
  background-color: #f8f9fa;
  padding: 4rem 2rem 2rem;
  color: #4a5568;
  border-top: 1px solid #e2e8f0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  gap: 4rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
}

.footer-section.brand {
  padding-right: 2rem;
}

.footer-section.brand p {
  margin: 1rem 0;
  color: #718096;
  line-height: 1.6;
}

.footer-social {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.footer-social a {
  color: #718096;
  transition: color 0.2s ease;
}

.footer-social a:hover {
  color: #4a5568;
}

.footer h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #2d3748;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li {
  margin-bottom: 0.75rem;
}

.footer ul li a {
  color: #718096;
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 0.95rem;
}

.footer ul li a:hover {
  color: #4a5568;
}

.footer-bottom {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
  text-align: center;
}

.footer-bottom p {
  color: #a0aec0;
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .footer-section.brand {
    grid-column: 1 / -1;
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  .footer {
    padding: 3rem 1.5rem 1.5rem;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }

  .footer-section {
    text-align: center;
  }

  .footer-social {
    justify-content: center;
  }
}