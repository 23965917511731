.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --dark-green: #005E3F;  /* Darker green color */
  --light-green: #007A52;
  --orange: #FF8C00;
  --light-orange: #FFA500;
}

.pricing-content {
  flex: 1;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.pricing-hero {
  text-align: center;
  padding: 6rem 2rem;
  background: var(--dark-green);
  color: white;
  position: relative;
  overflow: hidden;
}

.pricing-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.pricing-hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
  background: linear-gradient(120deg, #ffffff 0%, #f0f0f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing-hero .lead {
  font-size: 1.25rem;
  color: #e2e8f0;
  opacity: 0.9;
}

.pricing-plans {
  padding: 4rem 2rem;
  background: #f8f9fa;
}

.pricing-plans .container {
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto 4rem;
  padding: 0.75rem;
  background: rgba(0, 94, 63, 0.05);
  border-radius: 2rem;
  width: fit-content;
}

.pricing-toggle span {
  color: #333;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
}

.pricing-toggle span.active {
  opacity: 1;
  font-weight: 600;
  color: var(--dark-green);
}

.save-badge {
  background: #FF8C00;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: 0 0.5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 94, 63, 0.1);
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input:checked + .slider {
  background-color: var(--dark-green);
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.plans-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.plan-card {
  position: relative;
  background: white;
  border-radius: 16px;
  padding: 2.5rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid transparent;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plan-card.popular {
  border-color: #FF8C00;
  padding-top: 3rem;
}

.popular-tag {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #FF8C00;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 2;
  white-space: nowrap;
}

.plan-header {
  text-align: center;
  margin-bottom: 2rem;
}

.plan-name {
  margin-bottom: 1rem;
}

.plan-name h2 {
  font-size: 1.75rem;
  color: var(--dark-green);
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #666;
  font-size: 0.875rem;
}

.price {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--dark-green);
  margin: 1.5rem 0;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.25rem;
}

.price .currency {
  font-size: 1.5rem;
  font-weight: 600;
}

.price .amount {
  font-size: 2.5rem;
  font-weight: 700;
}

.price .period {
  font-size: 1rem;
  color: #666;
  font-weight: 400;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.plan-features li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #475569;
  font-size: 0.95rem;
}

.feature-icon {
  width: 20px;
  height: 20px;
  color: var(--dark-green);
  flex-shrink: 0;
}

.professional .feature-icon {
  color: var(--orange);
}

.plan-cta {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  background: var(--dark-green);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.plan-cta:hover {
  background: var(--light-green);
}

.starter .plan-cta {
  background-color: white;
  color: var(--dark-green);
  border: 2px solid var(--dark-green);
}

.starter .plan-cta:hover {
  background-color: var(--dark-green);
  color: white;
  transform: translateY(-2px);
}

.enterprise .plan-cta {
  background-color: white;
  color: var(--dark-green);
  border: 2px solid var(--dark-green);
}

.enterprise .plan-cta:hover {
  background-color: var(--dark-green);
  color: white;
  transform: translateY(-2px);
}

.pricing-faq {
  padding: 6rem 2rem 4rem;
  background: white;
}

.pricing-faq h2 {
  text-align: center;
  font-size: 2rem;
  color: var(--dark-green);
  margin-bottom: 3rem;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.faq-item h3 {
  color: var(--dark-green);
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.faq-item p {
  color: #475569;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .plans-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .plans-grid {
    gap: 1.5rem;
    padding: 0 1rem;
  }
  
  .plan-card {
    padding: 2rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .pricing-hero h1 {
    font-size: 2.5rem;
  }
  
  .plans-grid {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin: 2rem auto;
  }
  
  .plan-card {
    margin-bottom: 2rem;
  }

  .plan-card.professional {
    transform: none;
    margin: 2rem 0;
  }

  .plan-card:hover,
  .plan-card.professional:hover {
    transform: translateY(-5px);
  }
}
