.home {
    padding: 20px;
    margin-left: 250px;
  }
  
  h1 {
    font-size: 24px; 
    margin-bottom: 30px;
  }
  
  .home h2 {
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 20px;  
  }
  
  .step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 4px;
    background-color: #f0f0f0;
  }
  
  .step a {
    color: var(--primary-green);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
  }
  
  .step a:hover {
    text-decoration: underline;
  }
  
  .step .MuiSvgIcon-root {
    margin-right: 8px;
    margin-top: 5px;
    font-size: 20px;
    color: var(--primary-green);  
  }
  
  .small-text {
    margin: 4px 0 0 0;
    font-size: 14px;
    color: #666;
  }

  