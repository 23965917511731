.features-hero {
    text-align: center;
    padding: 4rem 2rem;
    background: var(--primary-green);
    color: white;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    margin: 6rem 2rem 2rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.features-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.features-hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at top right, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
}

.features-hero-content {
    position: relative;
    z-index: 1;
    max-width: 800px;
    margin: 0 auto;
}

.features-hero h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    background: linear-gradient(120deg, #ffffff 0%, #f0f0f0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.features-hero p {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2.5rem;
}

.features-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 2rem 0;
}

.stat-item {
    position: relative;
}

.stat-item::after {
    content: '';
    position: absolute;
    right: -1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
}

.stat-item:last-child::after {
    display: none;
}

.stat-number {
    font-size: 3.5rem;
    font-weight: 800;
    background: linear-gradient(120deg, #ffffff 0%, #f0f0f0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1;
    margin-bottom: 0.5rem;
    display: block;
}

.stat-label {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
}

.features-cta {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2.5rem;
}

.cta-button {
    padding: 0.875rem 1.75rem;
    border-radius: 12px;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
}

.cta-button:first-child {
    background: white;
    color: var(--primary-green);
}

.cta-button:first-child:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.cta-button.secondary {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    backdrop-filter: blur(10px);
}

.cta-button.secondary:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

.limited-offer {
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
    .features-hero {
        margin: 1rem;
        padding: 3rem 1.5rem;
    }

    .features-hero h2 {
        font-size: 2rem;
    }

    .features-stats {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .stat-item::after {
        display: none;
    }

    .stat-number {
        font-size: 2.5rem;
    }

    .features-cta {
        flex-direction: column;
    }

    .cta-button {
        width: 100%;
        justify-content: center;
    }
}

.features {
    padding: 8rem 2rem;
    position: relative;
    overflow: hidden;
}

.features-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        135deg,
        #f8fafb 0%,
        #ffffff 100%
    );
    z-index: -1;
}

.gradient-sphere {
    position: absolute;
    border-radius: 50%;
    filter: blur(80px);
    opacity: 0.5;
}

.gradient-sphere-1 {
    width: 400px;
    height: 400px;
    background: radial-gradient(circle at center, rgba(0, 94, 63, 0.1) 0%, rgba(0, 94, 63, 0) 70%);
    top: -100px;
    left: -100px;
    animation: float 8s ease-in-out infinite;
}

.gradient-sphere-2 {
    width: 300px;
    height: 300px;
    background: radial-gradient(circle at center, rgba(0, 94, 63, 0.08) 0%, rgba(0, 94, 63, 0) 70%);
    bottom: 10%;
    right: -50px;
    animation: float 10s ease-in-out infinite;
}

.gradient-sphere-3 {
    width: 250px;
    height: 250px;
    background: radial-gradient(circle at center, rgba(0, 94, 63, 0.05) 0%, rgba(0, 94, 63, 0) 70%);
    top: 40%;
    left: 60%;
    animation: float 12s ease-in-out infinite;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(-20px) scale(1.05);
    }
}

.features-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(rgba(0, 94, 63, 0.03) 1px, transparent 1px);
    background-size: 20px 20px;
    mask-image: radial-gradient(circle at center, black 0%, transparent 80%);
}

.features-header {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 6rem;
    position: relative;
}

.badge {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    background: rgba(0, 94, 63, 0.1);
    color: var(--primary-green);
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    overflow: hidden;
}

.badge::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transform: translateX(-100%);
    animation: shimmer 3s infinite;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.features-header h2 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    background: linear-gradient(135deg, var(--primary-green), #2c7a5d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
}

.features-header p {
    font-size: 1.25rem;
    color: #4a5568;
    line-height: 1.6;
    opacity: 0.9;
}

.features-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    border: 1px solid rgba(0, 94, 63, 0.1);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.nav-item {
    padding: 1rem 1.5rem;
    border: none;
    background: transparent;
    color: #4a5568;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.nav-item.active {
    background: var(--primary-green);
    color: white;
}

.nav-icon {
    font-size: 1.2rem;
}

.features-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.feature-category {
    display: none;
    margin-bottom: 6rem;
    animation: fadeIn 0.5s ease-out;
}

.feature-category.active {
    display: block;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.category-header {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 4rem;
}

.category-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #2d3748, #4a5568);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.category-description {
    font-size: 1.1rem;
    color: #4a5568;
    opacity: 0.9;
}

.feature-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.feature-card {
    position: relative;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
}

.feature-card::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 2px;
    background: linear-gradient(135deg, rgba(0, 94, 63, 0.3), rgba(0, 94, 63, 0.1));
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px) scale(1.02);
}

.feature-card:hover::before {
    opacity: 1;
}

.feature-card-inner {
    padding: 2rem;
}

.feature-icon {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, rgba(0, 94, 63, 0.1), rgba(0, 94, 63, 0.05));
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    position: relative;
    transition: all 0.3s ease;
}

.feature-card:hover .feature-icon {
    transform: scale(1.1);
    background: linear-gradient(135deg, rgba(0, 94, 63, 0.2), rgba(0, 94, 63, 0.1));
}

.feature-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.feature-content h4 {
    font-size: 1.25rem;
    color: #2d3748;
    font-weight: 600;
    margin: 0;
}

.feature-highlight {
    font-size: 0.85rem;
    background: linear-gradient(135deg, var(--primary-green), #2c7a5d);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-weight: 500;
}

.feature-content p {
    font-size: 1rem;
    color: #4a5568;
    line-height: 1.6;
    margin: 0 0 1rem 0;
    opacity: 0.9;
}

.feature-stats {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: var(--primary-green);
    font-weight: 500;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 94, 63, 0.1);
}

.stats-icon {
    font-size: 1.1rem;
}

.features-cta {
    position: relative;
    text-align: center;
    margin-top: 6rem;
    padding: 5rem;
    background: linear-gradient(135deg, var(--primary-green), #2c7a5d);
    border-radius: 24px;
    color: white;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.cta-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%) -20px 0,
        linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%) -20px 0,
        linear-gradient(45deg, transparent 75%, rgba(255, 255, 255, 0.1) 75%),
        linear-gradient(-45deg, transparent 75%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 40px 40px;
    opacity: 0.3;
}

.cta-badge {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.cta-content {
    position: relative;
    z-index: 1;
}

.cta-stats {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin: 3rem 0;
}

.stat-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.stat-number {
    font-size: 2.5rem;
    font-weight: 700;
    background: linear-gradient(135deg, #ffffff, rgba(255, 255, 255, 0.9));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.stat-label {
    font-size: 1rem;
    opacity: 0.9;
}

.features-cta h3 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    line-height: 1.2;
}

.features-cta p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    opacity: 0.9;
}

.cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.features-cta .primary-btn,
.features-cta .secondary-btn {
    padding: 1rem 2rem;
    font-size: 1.1rem;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.features-cta .primary-btn {
    background: white;
    color: var(--primary-green);
    border: none;
}

.features-cta .secondary-btn {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-icon {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

.primary-btn:hover .btn-icon,
.secondary-btn:hover .btn-icon {
    transform: translateX(5px);
}

.features-cta .primary-btn:hover,
.features-cta .secondary-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.features-cta .secondary-btn:hover {
    background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 1024px) {
    .features {
        padding: 6rem 2rem;
    }

    .features-header h2 {
        font-size: 2.5rem;
    }

    .features-nav {
        flex-wrap: wrap;
    }

    .feature-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }

    .features-cta {
        margin: 4rem 2rem 0;
        padding: 4rem 2rem;
    }

    .cta-stats {
        gap: 2rem;
    }

    .stat-number {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .features {
        padding: 4rem 1.5rem;
    }

    .features-header h2 {
        font-size: 2rem;
    }

    .category-title {
        font-size: 1.75rem;
    }

    .feature-grid {
        grid-template-columns: 1fr;
    }

    .feature-card-inner {
        padding: 1.5rem;
    }

    .features-cta {
        margin: 3rem 1rem 0;
        padding: 3rem 1.5rem;
    }

    .cta-stats {
        flex-direction: column;
        gap: 2rem;
    }

    .cta-buttons {
        flex-direction: column;
    }

    .features-cta h3 {
        font-size: 1.75rem;
    }
}

@media (max-width: 480px) {
    .features-header h2 {
        font-size: 1.75rem;
    }

    .badge {
        font-size: 0.8rem;
        padding: 0.4rem 0.8rem;
    }

    .feature-icon {
        width: 60px;
        height: 60px;
        font-size: 2rem;
    }

    .nav-item {
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
    }
}