.product-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: center;
    height: 100vh;
  }
  
  .form-label {
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 10px;
    font-weight: 600 !important;
    color: var(--primary-green) !important;
  }
  
  .radio-label {
    margin-bottom: 0px;
  }
  
  .radio-label .MuiRadio-root {
    color: var(--primary-green);
  }
  
  .radio-label .MuiRadio-root.Mui-checked {
    color: var(--primary-green);
  }
  
  .continue-button {
    margin-top: 20px !important;
    background-color: var(--primary-green);
    color: white;
    width: 100%;
  }
  
  .continue-button:hover {
    background-color: var(--primary-green-hover);
  }

  .product-services .radio-group {
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 6px;
    background-color: white;
    align-items: stretch;
    border: 1px solid #ccc;
   
  }

  