:root {
  --dark-green: #005E3F;
  --light-green: #007A52;
}

.about-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.about-content {
  flex: 1;
}

.about-hero {
  background: var(--dark-green);
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.about-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.about-hero h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(120deg, #ffffff 0%, #f0f0f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-hero .lead {
  font-size: 1.5rem;
  color: #e2e8f0;
  max-width: 600px;
  margin: 0 auto;
}

.about-story {
  padding: 6rem 2rem;
  background: #f8f9fa;
}

.about-story h2 {
  font-size: 2.5rem;
  color: var(--dark-green);
  margin-bottom: 2rem;
  text-align: center;
}

.about-story p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #475569;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.about-values {
  padding: 6rem 2rem;
  background: white;
}

.about-values h2 {
  font-size: 2.5rem;
  color: var(--dark-green);
  margin-bottom: 3rem;
  text-align: center;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.value-card {
  background: #f8f9fa;
  padding: 3rem 2rem;
  border-radius: 16px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.value-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.value-card h3 {
  font-size: 1.5rem;
  color: var(--dark-green);
  margin-bottom: 1rem;
}

.value-card p {
  color: #475569;
  line-height: 1.6;
}

.about-team {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  text-align: center;
}

.about-team h2 {
  font-size: 2.5rem;
  color: var(--dark-green);
  margin-bottom: 1.5rem;
}

.about-team > .container > p {
  font-size: 1.25rem;
  color: #475569;
  max-width: 800px;
  margin: 0 auto 4rem;
}

.team-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  margin-top: 3rem;
}

.stat {
  padding: 2rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.stat:hover {
  transform: translateY(-5px);
}

.stat h3 {
  font-size: 3rem;
  color: var(--dark-green);
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.stat p {
  color: #475569;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 3rem;
  }

  .about-hero .lead {
    font-size: 1.25rem;
  }

  .about-story,
  .about-values,
  .about-team {
    padding: 4rem 1.5rem;
  }

  .values-grid,
  .team-stats {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .stat {
    padding: 1.5rem;
  }
}
