.company-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.company-details h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--primary-green);
}

.company-details h2 {
  margin-bottom: 20px;
  font-size: 18px;
  color: black;
  font-weight: 600;
}

.company-details .input-group {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  align-items: stretch;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 400px;
}

.form-control {
  margin-bottom: 15px !important;
}

.input-field {
  margin-bottom: 15px !important;
}

.continue-button {
  margin-top: 20px;
  background-color: var(--primary-green) !important;
  color: white;
  padding: 10px 40px !important;
  font: inherit !important;
  font-size: 16px  !important;
  text-transform: none !important;
  border: none !important;
  font-weight: 500 !important;
  border-radius: 6px !important;
  width: auto;

}

.MuiInputLabel-root{
  background-color: white;
  padding: 0 5px !important;
}