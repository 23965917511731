:root {
  --primary-green: #045137;
  --dark-green: rgb(1, 26, 1);
  --primary-white: #f8f9fa;
  --primary-orange:#d05f0d;
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'DM Sans', sans-serif;
  line-height: 1.6;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}