.revenue-model-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
  }
  
.revenue-model-table th, .revenue-model-table td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

.revenue-model-table th {
  background-color: #f2f2f2;
}

.revenue-model-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.revenue-model-table tr:hover {
  background-color: #e6e6e6;
}

.revenue-model-table td:first-child,
.revenue-model-table td:nth-child(3),
.revenue-model-table td:nth-child(4),
.revenue-model-table td:nth-child(2) {
  font-weight: bold;
  text-align: left;
}


.total-revenue-row td {
  font-weight: bold;
  background-color: #f2f2f2;
}