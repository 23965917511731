.mission {
    padding: 6rem 2rem;
    background-color: var(--dark-green);
    position: relative;
    overflow: hidden;
}

.mission::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.mission-header {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 4rem;
    position: relative;
    z-index: 1;
}

.mission-header h2 {
    font-size: 2.5rem;
    color: white;
    margin-bottom: 1rem;
    line-height: 1.2;
}

.mission-header p {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto 5rem;
    padding: 2rem;
    position: relative;
    z-index: 1;
}

.stat-card {
    text-align: center;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
}

.stat-card:hover {
    transform: translateY(-5px);
}

.stat-card h3 {
    font-size: 2.5rem;
    color: white;
    margin-bottom: 0.5rem;
}

.stat-card p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.1rem;
}

.testimonials-section {
    max-width: 1200px;
    margin: 0 auto 5rem;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
}

.testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.testimonial-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.testimonial-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.testimonial-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.testimonial-author h4 {
    font-size: 1.1rem;
    color: white;
    margin-bottom: 0.25rem;
}

.testimonial-author p {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
}

.testimonial-card blockquote {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
    font-style: italic;
}

.trusted-by {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
}

.trusted-by h3 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 2rem;
}

.company-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    flex-wrap: wrap;
    padding: 2rem 0;
}

.company-logo {
    height: 35px;
    width: auto;
    opacity: 0.7;
    transition: all 0.3s ease;
    filter: brightness(0) invert(1);
}

.company-logo:hover {
    opacity: 1;
    transform: translateY(-2px);
}

@media (max-width: 1024px) {
    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .testimonials-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .mission {
        padding: 4rem 1.5rem;
    }

    .mission-header h2 {
        font-size: 2rem;
    }

    .testimonials-grid {
        grid-template-columns: 1fr;
    }

    .company-logos {
        gap: 2rem;
        padding: 1rem 0;
    }

    .company-logo {
        height: 25px;
    }
}

@media (max-width: 480px) {
    .stats-grid {
        grid-template-columns: 1fr;
    }
}