.customer-model-organic-table {
    border-collapse: collapse;
    width: 100%;
    font-family: inherit;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  
  .customer-model-organic-table th, .customer-model-organic-table td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .customer-model-organic-table th {
    background-color: #eff4ff;
  }
  
  .customer-model-organic-table tr:nth-child(even) {
    background-color: #eff4ff;
  }
  
  .customer-model-organic-table tr:hover {
    background-color: #e6e6e6;
  }
  
  .customer-model-organic-table td:first-child {
    font-weight: bold;
    text-align: left;
  }

  .customer-model-organic-table td {
    font-size: 14px;
  }
  
  .customer-model-organic-table td p {
    font-size: 14px;
    font-weight: 400;
  }
  
  .year-segment {
    margin-top: 40px;
  }
  